<template>
  <vxe-pulldown ref="xDown" transfer style="width: 100%">
    <template #default>
      <vxe-input v-model="name" style="width: 100%;" size="small" :suffix-icon="!readonly?'vxe-icon-search':''" :placeholder="placeholder" @click="showDropdown" readonly></vxe-input>
    </template>
    <template #dropdown>
      <vxe-table
          :show-header="false"
          max-height="300"
          show-overflow
          :row-config="{isHover: true,isCurrent: true,useKey: true}"
          :column-config="{resizable: true}"
          :data="tableData"
          size="mini"
          @cell-click="cellClickEvent"
          :scroll-y="{enabled: false}">
        <vxe-column field="name" title="名称"></vxe-column>
      </vxe-table>
    </template>
  </vxe-pulldown>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      name: '',
      dataLoading: false,
      localValue: ''
    }
  },
  created: function () {
    this.getInfo()
  },
  props: {
    value: [String, Number],
    placeholder: {
      type: String,
      default: '请选择分类'
    },
    readonly: Boolean
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    // 数据回显
    value: function () {
      this.getInfo()
    }
  },
  methods: {
    showDropdown() {
      if (!this.readonly) {
        if (!this.tableData || this.tableData.length == 0) {
          this.getDataList()
        }
        this.$refs.xDown.showPanel()
      }
    },
    cellClickEvent({row}) {
      this.showName(row)
      this.localValue = row.id
      this.$emit('change', row.id)
      this.$refs.xDown.hidePanel()
    },
    getInfo() {
      // 因组件内部选择了某选项，双向绑定后导致的value变化，所以不作处理
      if (this.value === this.localValue) {
        return
      }
      this.localValue = this.value
      if (this.value) {
        this.$http.get('/mps/archiveMetaType/' + this.value).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.showName(res.data)
        }).catch(() => {
          // do nothing
        })
      }
    },

    showName(data) {
      this.name = data && data.name ? data.name : ''
    },

    getDataList() {
      if (!this.dataLoading) {
        this.dataLoading = true
        this.$http.get('/mps/archiveMetaType/list').then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.tableData = res.data
        }).catch(() => {
          // do nothing
        }).finally(() => {
          this.dataLoading = false
        })
      }
    }
  },
}
</script>
<style scoped>
.name-disabled {
  color: rgba(128, 128, 128, 0.54)
}
</style>
