<template>
  <el-dialog :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="auto">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="code" label="工程或费用编码">
            <el-input v-model="dataForm.code" placeholder="工程或费用编码"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="上级节点" prop="pid">
            <estmodel-parent-select v-model="dataForm.pid" :prjId="dataForm.prjId"></estmodel-parent-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="name" label="名称">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unitId" label="计量单位">
            <unit-select v-model="dataForm.unitId" :prj-id="dataForm.prjId"></unit-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="主要工作内容" prop="contents">
            <el-input type="textarea" v-model="dataForm.contents" autosize></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" autosize></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="全码" prop="keyCode">
            <span style="color:coral;">{{ dataForm.keyCode }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="level" label="概算类型">
            <span v-if="dataForm.level == 1" style="color:rgb(34, 11, 240);">部</span>
            <span v-if="dataForm.level == 2" style="color:rgb(238, 197, 17);">项</span>
            <span v-if="dataForm.level == 3" style="color:blueviolet;">目</span>
            <span v-if="dataForm.level == 4" style="color:chocolate;">节</span>
            <span v-if="dataForm.level >= 5" style="color:green;">细目</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 去掉label页面更和谐 -->
          <el-form-item label="">
            <!-- 去掉 inactive-text 页面更和谐 -->
            <el-switch
                v-model="autoCalculate"
                active-text="自动计算"
                inactive-text=""
                @change="autoCalculateChange">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="autoCalculate">
          <el-form-item label="数据来源" prop="autoCalculateNames">
            <el-tooltip :disabled="!dataForm.autoCalculateNames" :content="dataForm.autoCalculateNames" effect="light">
              <el-input v-model="dataForm.autoCalculateNames" placeholder="点击选择" readonly @click.native="openEstmodelMultiSelect"></el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item prop="refCode" label="部版概算分表编码">
            <el-input v-model="dataForm.refCode" placeholder="映射编码不一致的标段分项"></el-input>
          </el-form-item>
        </el-col>        
        <el-col :span="8">
          <el-form-item prop="sortNo" label="排序序号">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <estmodel-multi-select ref="estmodelMultiSelect" v-if="estmodelMultiSelectVisible" @confirm="estmodelMultiSelectDone" @close="estmodelMultiSelectVisible = false"></estmodel-multi-select>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import EstmodelMultiSelect from '@/views/modules/mps/estmodel-multi-select'
import EstmodelParentSelect from './estmodel-parent-select'
export default {
  data () {
    return {
      visible: false,
      defaultParentName: '无',
      dataForm: {
        id: '',
        prjId: '',
        pid: '',
        name: '',
        code: '',
        refCode: '',
        keyCode: '',
        level: '',
        unitId: '',
        sortNo: 0,
        autoCalculateIds: '',
        autoCalculateNames: '',
        parentName: '无',
        contents: '',
        remark: '',
      },
      autoCalculate: false,
      row: {},
      estmodelMultiSelectVisible: false,
      oldPid:'' // 记录数据原始的上级节点ID
    }
  },
  computed: {
    dataRule () {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  components: {
    EstmodelMultiSelect,
    EstmodelParentSelect
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // 获取最新全码
          this.getNewKeyCode()
          // 获取排序序号
          this.getNewSortNo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get(`/mps/estModel/${this.dataForm.id}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.autoCalculateIds) {
          this.autoCalculate = true
        }
        if (this.dataForm.pid === '0') {
          this.dataForm.parentName = this.defaultParentName
        }
        this.oldPid = this.dataForm.pid
      }).catch(() => {
        // do nothing
      })
    },
    // 获取【全码】
    getNewKeyCode() {
      this.$http.get(`/mps/estModel/${this.dataForm.prjId}/${this.dataForm.pid}/newKeyCode`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (!res.data) {
          return this.$message.error("全码自动生成失败！")
        }
        this.dataForm.keyCode = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 获取排序序号
    getNewSortNo() {
      this.$http.get(`/mps/estModel/${this.dataForm.prjId}/${this.dataForm.pid}/newSortNo`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm.sortNo = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/estModel', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (this.dataForm.id) {
            this.$emit('updateNode', this.dataForm.pid, this.oldPid != this.dataForm.pid)
          } else {
            this.$emit('addNode', this.dataForm.pid, this.row)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
            }
          })
        }).catch(() => {
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),
    openEstmodelMultiSelect() {
      this.estmodelMultiSelectVisible = true
      this.$nextTick(() => {
        this.$refs.estmodelMultiSelect.dataForm.id = this.dataForm.id
        this.$refs.estmodelMultiSelect.dataForm.prjId = this.dataForm.prjId
        if (this.dataForm.autoCalculateIds) {
          this.$refs.estmodelMultiSelect.defaultCheckedKeys = this.dataForm.autoCalculateIds.split(',')
        }
        this.$refs.estmodelMultiSelect.init()
      })
    },
    estmodelMultiSelectDone(models) {
      let idArr = []
      let nameArr = []
      for (let model of models) {
        idArr.push(model.id)
        nameArr.push(model.name)
      }
      this.dataForm.autoCalculateIds = idArr.join(',')
      this.dataForm.autoCalculateNames = nameArr.join(',')
    },
    autoCalculateChange(e) {
      if (!e) {
        this.dataForm.autoCalculateIds = ''
        this.dataForm.autoCalculateNames = ''
      }
    }
  }
}
</script>
