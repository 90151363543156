<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__preprojectworklib">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <pre-project-select width="380px" v-model="dataForm.prePrjId" placeholder="前期项目选择" :defaultSelect="true" @change="preprojectSelectHandle"></pre-project-select>
        </el-form-item>
        <el-form-item v-if="dataForm.prePrjId" label="时间尺度" prop="scaleUnit">
          <el-radio-group v-model="dataForm.scaleUnit" @input="changeScaleUnit">
            <el-radio label="year">年</el-radio>
            <el-radio label="month">月</el-radio>
            <el-radio label="week">周</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div ref="gantt" class="gantt-container my-gantt"/>
    </div>
  </el-card>
</template>

<script>
import gantt from "dhtmlx-gantt";

export default {
  data() {
    return {
      dataForm: {
        prePrjId: '',
        scaleUnit: 'month',
      },
      tasks: {
        data: []
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    // 前期项目变化事件
    preprojectSelectHandle(data) {
      this.tasks.data = []
      this.getDataList()
    },
    // 初始化甘特图组件
    init() {
      this.$nextTick(() => {
        gantt.clearAll()
        // 只读
        gantt.config.readonly = true
        // 自动延长时间刻度
        gantt.config.fit_tasks = true
        // 甘特图图表宽度自适应
        gantt.config.autofit = true
        gantt.config.xml_date = "%Y-%m-%d"

        gantt.config.step = 1
        gantt.config.date_scale = "%Y年%M"
        // 允许拖放
        //gantt.config.drag_project = true
        gantt.i18n.setLocale('cn') // 设置中文

        // 第一个时间尺度单位
        gantt.config.scale_unit = this.dataForm.scaleUnit

        gantt.config.show_task_cells = true

        //  列自定义
        gantt.config.columns = [
          {
            name: 'text',
            label: '工作名称',
            tree: true,
            resize: true,
            width: "*",
            min_width: 240,
            template: function (obj) {
              return `${obj.text}`
            },
          },
          {
            name: 'start_date',
            label: '计划开始',
            min_width: 120
          },
          {
            name: 'end_date',
            label: '计划结束',
            min_width: 120
          },
          {
            name: 'progress',
            label: '完成进度',
            width: 90,
            align: 'center',
            template: function (obj) {
              return `${obj.progress * 100} %`
            },
          },
        ]

        // gantt.config.tooltip_offset_x = 10
        // gantt.config.tooltip_offset_y = 30

        // 点击表头可排序
        gantt.config.sort = true

        gantt.plugins({
          tooltip: false,
          quick_info: true,// 快速信息框
        })
        gantt.config.show_quick_info = true

        gantt.templates.quick_info_title = function (start, end, task) {
          return task.text.substr(0, 24)
        }

        gantt.templates.quick_info_content = function (start, end, task) {
          return task.text
        }

        Date.prototype.format = function (fmt) {
          var o = {
            "M+": this.getMonth() + 1, //月份
            "d+": this.getDate(), //日
            "h+": this.getHours(), //小时
            "m+": this.getMinutes(), //分
            "s+": this.getSeconds(), //秒
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度
            "S": this.getMilliseconds() //毫秒
          };
          if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
          for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
          return fmt;
        }

        gantt.templates.quick_info_date = function (start, end, task) {
          return new Date(start).format("yyyy-MM-dd") + " → " + new Date(end).format("yyyy-MM-dd")
        }

        gantt.templates.task_text = function (start, end, task) {
          if (task.progress != 0) {
            return `完成 ${task.progress * 100}%`;
          } else {
            return task.text;
          }
        }

        // 初始化
        gantt.init(this.$refs.gantt)

      })
    },

    // 甘特图时间尺度变化事件
    changeScaleUnit() {
      gantt.config.scale_unit = this.dataForm.scaleUnit
      gantt.render();
    },

    // 获取前期工作进度数据
    getDataList() {
      this.$http.get(
          '/opm/preProjectWorkLib/gantt',
          {
            params: {
              ...this.dataForm
            }
          }).then(({data: res}) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        }
        if (res.data != null && res.data.length > 0) {
          this.tasks.data = res.data
        }
        var removeNullProperty = function (obj) {
          Object.keys(obj).forEach(item => {
            if (item === 'parent' && (!obj[item] || obj[item] === 0)) delete obj[item]
          })
          return obj
        }
        // 去掉为null或0的parent属性
        for (const row in this.tasks.data) {
          removeNullProperty(row)
        }
        // 清除现有的任务数据
        gantt.clearAll();
        // 数据解析
        gantt.parse(this.tasks)
      }).catch(() => {
        this.$message.error('加载出错')
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.my-gantt {
  width: 100%;
  height: calc(100vh - 180px);
}
</style>