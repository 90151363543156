<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__sub">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <contract-cascader ref="contractCascader" include-sub @change="contractChange"></contract-cascader>
        </el-form-item>
      </el-form>
      <el-table
        v-if="tableShow"
        ref="subTable"
        v-loading="tableLoading"
        :data="tableData"
        row-key="id"
        lazy
        @row-click="rowClick"
        :load="loadTableData"
        @selection-change="dataListSelectionChangeHandle"
        border
        highlight-current-row
        style="width: 100%;"
        :height="subTableHeight"
        :cell-style="{padding: '2px 0'}"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="部位" header-align="center" min-width="150"></el-table-column>
        <el-table-column prop="code" label="编号" header-align="center" min-width="200"></el-table-column>
        <el-table-column prop="unitName" label="单位" header-align="center" align="center" width="100"></el-table-column>
<!--        <el-table-column prop="num" label="工程量" header-align="center" align="center" width="160"></el-table-column>-->
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="120">
          <template v-slot="{row}">
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], true, row.name)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-loading="boqTableLoading" :data="boqTableData" highlight-current-row border style="width: 100%;" :height="boqTableHeight">
        <el-table-column prop="boqCode" label="清单编号" header-align="center" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="boqName" label="清单名称" header-align="center" min-width="160" show-overflow-tooltip></el-table-column>
        <el-table-column prop="subItemCode" label="部位编号" header-align="center"  min-width="240" show-overflow-tooltip></el-table-column>
        <el-table-column prop="subItemName" label="部位名称" header-align="center"  min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="measSum" label="单位" header-align="center" align="center" width="100"></el-table-column>
        <el-table-column prop="num" label="合同数量" header-align="center" align="right" width="160">
          <template v-slot="{row}">
            <span>{{row.num}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="measSum" label="累计计量" header-align="center" align="right" width="160"></el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
      },
      dataForm: {
        prjId: this.$store.state.user.prjId,
        contractId: '',
        subcontractorId: '',
      },
      treeLoading: false,
      treeProps: {
        children: 'children',
        label: 'name',
      },
      tableLoading: false,
      tableData: [],
      tableShow: true,
      boqTableHeight: 300,
      subTableHeight: 400,
      boqTableLoading: false,
      boqTableData: [],
    }
  },
  created() {
    let _height = window.innerHeight - 200
    this.subTableHeight = _height * 0.6
    this.boqTableHeight = _height * 0.4
  },
  methods: {
    // el-table懒加载节点
    loadTableData(data, node, resolve) {
      let pid = data.id
      this.$http.get(
          '/mps/subItem/children',
          {
            params: {
              'pid': pid,
              ...this.dataForm
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        resolve(res.data);
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    // 项目、标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      this.boqTableData = []
      this.getTableData()
    },
    getTableData() {
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
      this.tableLoading = true
      this.$http.get(
          '/mps/subItem/children',
          {
            params: {
              pid: 0,
              ...this.dataForm
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        this.tableData = res.data
        this.tableLoading = false
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    rowClick(row) {
      this.getSubBoqData(row.id)
    },
    // 加载零号清单及其累计计量
    getSubBoqData(subItemId) {
      this.boqTableLoading = true
      this.$http.get('/mps/subItem/subBoqWithMeas', {
            params: {
              subItemId: subItemId,
              ...this.dataForm
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.boqTableData = res.data
        if (this.boqTableData == null || this.boqTableData.length == 0) {
          this.$message({
            message: '未查询到相关的零号清单。',
            type: 'warning',
            duration: 2000,
            onClose: () => {
              // do nothing
            }
          })
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.boqTableLoading = false
      })
    },
  }
}
</script>
<style scoped>
</style>