import { render, staticRenderFns } from "./archivemeta.vue?vue&type=template&id=96c0d850&scoped=true"
import script from "./archivemeta.vue?vue&type=script&lang=js"
export * from "./archivemeta.vue?vue&type=script&lang=js"
import style0 from "./archivemeta.vue?vue&type=style&index=0&id=96c0d850&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96c0d850",
  null
  
)

export default component.exports