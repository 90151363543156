<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <div class="tree-container">
          <el-tree ref="typeTree"
                   lazy
                   class="left-tree"
                   node-key="id"
                   v-loading="typeTreeLoading"
                   :data="typeTreeData"
                   :props="defaultProps"
                   :load="loadTypeTreeNode"
                   :expand-on-click-node="false"
                   @node-click="typeTreeNodeClick"
                   :highlight-current="true">
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__material">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <el-input v-model="dataForm.code" placeholder="编号" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.name" placeholder="名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                <toolbar-button role="add" v-if="$hasPermission('mps:material:save')" @click="addOrUpdateHandle()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('mps:material:delete')" @click="deleteHandle()"></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
            <el-table-column type="selection" align="center" width="50"></el-table-column>
            <el-table-column prop="typeName" label="分类名称" width="180" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="code" label="编号" width="150" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="name" label="名称" min-width="150" sortable="custom" show-overflow-tooltip>
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.name }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="unit1" label="单位" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <!--<el-table-column prop="unit2" label="结算单位" width="80" align="center" show-overflow-tooltip></el-table-column>-->
            <el-table-column :label="$t('handle')" fixed="right" align="center" width="120">
              <template v-slot="{row}">
                <table-button role="update" v-if="$hasPermission('mps:material:update')" @click="addOrUpdateHandle(row.id)"></table-button>
                <table-button role="delete" v-if="$hasPermission('mps:material:delete')" @click="deleteHandle(row.id)"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 查看 -->
          <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
          <!-- 弹窗, 新增 / 修改 -->
          <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
  import mixinViewModule from '@/mixins/view-module'
  import AddOrUpdate from './material-add-or-update'
  import ViewPage from './material-view'

  export default {
    mixins: [mixinViewModule],
    data () {
      return {
        mixinViewModuleOptions: {
          createdIsNeed: false,
          getDataListURL: '/mps/material/page',
          getDataListIsPage: true,
          exportURL: '/mps/material/export',
          deleteURL: '/mps/material',
        },
        dataForm: {
          name: '',
          code: '',
          typeId: ''
        },
        findChildrenUrl: '/mps/materialType/findChildren',
        typeTreeLoading: false,
        typeTreeData: [],
        defaultProps: {
          children: 'children',
          label: 'name',
          isLeaf: 'isLeaf'
        },

        currentSelectTypeItem: null, // 当前选择的物资分类
        // 默认排序
        order:'asc',
        orderField:'sort_no',
      }
    },
    created () {
      this.initData()
    },
    watch: {},
    components: {
      AddOrUpdate,
      ViewPage
    },
    methods: {
      initData () {
        this.dataForm.typeId = ''
        this.getDataList()
        this.getTypeTreeData()
      },
      getTypeTreeData () {
        this.typeTreeLoading = true
        // 清空物资分类数据
        this.typeTreeData = []
        this.$http.get(this.findChildrenUrl,
          {
            params: {
              'pid': 0
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.typeTreeData = res.data || []
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.typeTreeLoading = false
        })
      },
      //异步加载物资分类子节点数据
      loadTypeTreeNode (node,resolve) {
        //此方法是进入页面后自动运行的,两个参数也是默认加载的
        if (node.level >= 1) {
          if (node && node.data) {
            let nodeData = node.data;
            if (nodeData.id == '-1') {
              return resolve([])
            } else {
              this.$http.get(this.findChildrenUrl,
                {
                  params: {
                    pid: nodeData.id
                  }
                }
              ).then(({data: res}) => {
                if (res.code !== 0) {
                  return this.$message.error(res.msg)
                }
                resolve(res.data);
              }).catch(() => {
                return this.$message.error('出错了')
              })
            }
          } else {
            return resolve([])
          }
        }
      },

      //  物资分类树节点点击事件
      typeTreeNodeClick (data,node,ele) {
        // 点击高亮节点则取消高亮
        if (this.dataForm.typeId == data.id) {
          this.currentSelectTypeItem = null
          this.dataForm.typeId = ''
          this.$refs.typeTree.setCurrentKey(null)
        } else {
          this.dataForm.typeId = data.id
          this.currentSelectTypeItem = data
        }
        // 查询数据
        this.getDataList()
      },

      // 新增 / 修改
      addOrUpdateHandle (id) {
        let currentNode = this.$refs.typeTree.getCurrentNode();
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = id
          // 新增时，物资分类叶子节点才赋值
          if(!id && this.currentSelectTypeItem){
               if(this.currentSelectTypeItem.isLeaf){
                 this.$refs.addOrUpdate.dataForm.typeId = this.dataForm.typeId
               }
          }
          this.$refs.addOrUpdate.init()
        })
      },
    }
  }
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 130px);
  }
</style>