<template>
  <el-dialog :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="报表名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="名称" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报表编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="编号" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报表分类" prop="typeId">
            <el-select v-model="dataForm.typeId" clearable disabled class="w-percent-100">
              <el-option value="1" label="施工单位计量报表"></el-option>
              <el-option value="2" label="监理单位计量报表"></el-option>
              <el-option value="3" label="造价管理台账报表"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="dsId" label="数 据 源">
            <el-select v-model="dataForm.dsId" clearable placeholder="请绑定数据源" class="w-percent-100" disabled>
              <el-option :value="0" label="通用数据源"></el-option>
              <el-option :value="1" label="中期支付证书"></el-option>
              <el-option :value="2" label="清单计量支付表"></el-option>
              <el-option :value="42" label="清单计量支付表（暂定计量）"></el-option>
              <el-option :value="3" label="中间计量汇总表"></el-option>
              <el-option :value="43" label="中间计量汇总表（暂定计量）"></el-option>
              <el-option :value="4" label="中间计量表"></el-option>
              <el-option :value="44" label="中间计量表（暂定计量）"></el-option>
              <el-option :value="5" label="开工预付款申请"></el-option>
              <el-option :value="6" label="开工预付款扣回"></el-option>
              <el-option :value="7" label="扣回材料设备款一览表"></el-option>
              <el-option :value="8" label="工程变更一览表"></el-option>
              <el-option :value="9" label="工程变更汇总表"></el-option>
              <el-option :value="10" label="监理服务费支付报表"></el-option>
              <el-option :value="11" label="工程进度表"></el-option>
              <el-option :value="12" label="扣回动员预付款一览表"></el-option>

              <!-- 以下是广东造价台账（概预算报表）数据源 -->
              <el-option :value="30" label="造价管理台账封面"></el-option>
              <el-option :value="31" label="造价管理台账1"></el-option>
              <el-option :value="32" label="造价管理台账2"></el-option>
              <el-option :value="33" label="造价管理台账3"></el-option>
              <el-option :value="34" label="造价管理台账4"></el-option>
              <el-option :value="35" label="造价管理台账5"></el-option>
              <el-option :value="36" label="造价管理台账6"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="每页条数" prop="rows">
            <el-input-number v-model="dataForm.rows" readonly></el-input-number>
          </el-form-item>
        </el-col>
        
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input v-model="dataForm.sortNo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input v-model="dataForm.remark" placeholder="备注" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        tenantCode: '',
        name: '',
        code: '',
        typeId: '',
        dsId: '',
        remark: '',
        jsonData: '',
        sortNo: '',
        creator: '',
        createDate: '',
        updater: '',
        updateDate: '',
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/reportTemplate/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
  }
}
</script>
