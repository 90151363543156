<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属分类" prop="typeId">
            <archivemeta-type-select v-model="dataForm.typeId"></archivemeta-type-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据类型" prop="dataType">
            <el-select v-model="dataForm.dataType" placeholder="请选择" class="w-percent-100">
              <el-option
                  v-for="item in dataTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="dataForm.id" label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import ArchivemetaTypeSelect from './archivemetatype-select'

export default {
  components: {
    ArchivemetaTypeSelect
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        typeId: '',
        code: '',
        name: '',
        dataType: 1,
        sortNo: ''
      },
      dataTypeOptions: [{
        value: 1,
        label: '文本'
      }, {
        value: 2,
        label: '日期'
      }, {
        value: 3,
        label: '数值'
      }],
    }
  },
  computed: {
    dataRule() {
      return {
        typeId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        dataType: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/archiveMeta/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        //  do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/archiveMeta/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>