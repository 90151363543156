<template>
  <el-dialog top="5vh" width="80%" :visible.sync="visible" title="选择自动计算源" :close-on-click-modal="false" append-to-body :close-on-press-escape="false" v-on="$listeners">
    <div class="my-tree-contianer">
      <el-tree
        node-key="id"
        :props="props"
        ref="tree"
        lazy
        show-checkbox
        check-strictly
        :load="load"
        :default-checked-keys="defaultCheckedKeys"
        highlight-current
        class="my-el-tree"
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span>{{ data.code }}</span>
          &nbsp;
          <span>{{ data.name }}</span>
        </span>
      </el-tree>
    </div>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
      },
      props: {
        label: 'name',
        isLeaf: 'isLeaf',
        disabled: this.checkBoxDisabled
      },
      defaultCheckedKeys: [],
      viewType: 1, // 1:按清单选择，2：按部位选择
    }
  },
  methods: {
    // 如果展开清单非叶节点，则加载下级清单
    // 如果展开清单叶节点, 则加载该清单已分解的零号清单
    load(node, resolve) {
      let pid
      if (node.level == 0) {
        pid = 0
      } else {
        pid = node.data.id
      }
      this.$http.get(
          '/mps/estModel/children',
          {
            params: {
              prjId: this.dataForm.prjId,
              pid: pid
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        resolve(res.data);
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    init() {
      this.visible = true
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      let checkedNodes = this.$refs.tree.getCheckedNodes(false)
      this.visible = false
      this.$emit('confirm', checkedNodes)
    }, 1000, {'leading': true, 'trailing': false}),
    checkBoxDisabled(data, node) {
      return data.id == this.dataForm.id
    },
  }
}
</script>
<style scoped>
.my-tree-contianer {
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
}
</style>