<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__safetystaff">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
      </el-form>
      <vxe-table
          border
          resizable
          auto-resize
          show-overflow
          keep-source
          ref="tableRef"
          :loading="dataListLoading"
          :height="tableHeight"
          :edit-config="editConfig"
          :cell-class-name="cellClassName"
          @edit-closed="editClosedEvent"
          :data="dataList">
        <vxe-column type="seq" width="50" align="center">
          <template v-slot:header>
            <i v-if="cellCanEdit()" class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;font-size: 20px" @click="autoAddNewRow"></i>
          </template>
        </vxe-column>
        <vxe-column field="name" width="120" :edit-render="{name: '$input'}">
          <template #header>
            <span v-if="cellCanEdit()" style="color: red;">*姓名</span>
            <span v-else>姓名</span>
          </template>
        </vxe-column>
        <vxe-column field="certificateNo" title="证书编号" width="200" :edit-render="{name: '$input'}"></vxe-column>
        <vxe-column field="technicalTitle" title="技术职称" width="160" :edit-render="{name: '$input'}"></vxe-column>
        <vxe-column field="years" title="从事安全工作年限(年)" width="160" align="center" :edit-render="{name: '$input', props: {type: 'integer', min: 0, max: 500, controls: false}}"></vxe-column>
        <vxe-column field="duty" title="项目职务" width="160" :edit-render="{name: '$input'}"></vxe-column>
        <vxe-column field="remark" title="备注" :edit-render="{name: '$input'}"></vxe-column>
        <vxe-column title="操作" width="80" align="center" fixed="right">
          <template #default="{row}">
            <table-button role="delete" v-if="$hasPermission('opm:safetystaff:delete')&&row.id" @click="deleteHandle(row.id)"></table-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      dataForm: {
        contractId: ''
      },
      dataListLoading: true,
      dataList: [],
      editConfig: {
        enabled: this.cellCanEdit(),
        trigger: 'click',
        mode: 'row',
        showStatus: true,
      },
      tableHeight: 500
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 160;
    })
  },
  methods: {
    // 标段切换
    contractChange(data) {
      this.dataList = []
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },
    getDataList() {
      this.dataListLoading = true
      this.$http.get(
          '/opm/safetyStaff/list',
          {
            params: {
              ...this.dataForm
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$messge.error(res.msg)
        }
        this.dataList = res.data
        // 默认补充空白行数
        if (this.cellCanEdit()) {
          this.autoAddNewRow()
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    cellClassName({row, column}) {
      if (this.cellCanEdit()) {
        return 'cell-underline'
      } else {
        return ''
      }
    },
    // 单元格是否可编辑
    cellCanEdit() {
      return this.$hasPermission('opm:safetystaff:edit')
    },
    // 单元格退出编辑事件
    editClosedEvent({row, column}) {
      const field = column.property
      // 如果该行数据的id值为空，则新增数据,反之则修改。
      let id = row.id
      let name = row.name
      if (!name) {
        this.$alert('请先填写姓名，否则此行数据不会保存！', '提示', {
          confirmButtonText: '确定'
        });
        return
      }
      let data = {id: row.id, contractId: this.dataForm.contractId}
      if (!row.id) {
        data.sortNo = row.sortNo
      }
      data[field] = row[field]
      this.$http[!id ? 'post' : 'put']('/opm/safetyStaff/', data).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg);
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 1000,
          onClose: () => {
            if (!id) {
              row.id = res.data
            }
          }
        })
      }).catch(() => {
        // do nothing
      })
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(this.$t('prompt.info', {'handle': this.$t('delete')}), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete(
            `/opm/safetyStaff`,
            {
              'data': [id]
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getDataList()
            }
          })
        }).catch(() => {
          // do nothing
        })
      }).catch(() => {
        // do nothing
      })
    },
    // 新增征迁进度，前端自动生成10行空记录
    autoAddNewRow() {
      let num = 10
      if (this.cellCanEdit()) {
        for (let i = 1; i <= num; i++) {
          let data = {
            id: '',
            sortNo: this.dataList.length + 1
          }
          this.dataList.push(data)
        }
      }
    },
  }
}
</script>